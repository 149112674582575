<template>
  <div class="outDiv">
    <div class="up-link">
      <div class="link-info">
        <div class="screen-title">
          <div class="left-line"></div>
          <p>链上信息</p>
        </div>
        <div class="result-search">
          <div class="name calamity-type-name">
            <el-input
              v-model.trim="linkName"
              autocomplete="off"
              placeholder="请输入你需要搜索的批次号"
              maxlength="20"
            ></el-input>
          </div>
          <div class="searchBtn" @click="getLinkSearch">查询</div>
        </div>
        <MyTable :tableData="tableData" :configTable="configTable"></MyTable>
        <el-pagination
          v-if="tableData.length"
          background
          layout="prev, pager, next"
          :total="total"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
      <div class="link-status">
        <div class="screen-title">
          <div class="left-line"></div>
          <p>链上状态</p>
        </div>
        <div class="status-card-box">
          <div
            class="card-items"
            :style="{ background: item.color }"
            v-for="(item, index) in statusCardList"
            :key="index"
          >
            <img class="card-imgs" :src="item.img" alt="" />
            <div class="card-infos">
              <p>{{ item.title }}</p>
              <VueCountUp
                class="count-up"
                :start-value="0"
                :end-value="item.data"
                :duration="2"
                :options="CountUpOptions"
                :decimals="0"
                :delay="1"
              />
            </div>
          </div>
        </div>
        <div class="chart-personnel">
          <v-chart
            class="v-chart"
            autoresize
            :option="line_personnel"
          ></v-chart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueCountUp from "vue-countupjs";
import {
  postTranceChainApi,
  getBatchListApi,
  getLinkStatusInfoApi,
  // getLinkChartApi,
} from "@/request/api.js";
import moment from "moment";

import { mapState } from "vuex";
import { TitleComponent, TooltipComponent } from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import { use } from "echarts/core";
import { LineChart } from "echarts/charts";
import { LabelLayout, UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
use([
  CanvasRenderer,
  TitleComponent,
  TooltipComponent,
  LineChart,
  LabelLayout,
  UniversalTransition,
]);
export default {
  components: {
    VueCountUp,
    VChart,
    MyTable: () => import("@/components/MyTable.vue"),
  },
  provide: {
    [THEME_KEY]: "dark",
  },
  data() {
    return {
      secret_key: [
        69, 9, 0, 96, 250, 47, 130, 235, 56, 153, 223, 60, 178, 81, 155, 106,
        223, 131, 155, 27, 28, 84, 61, 242, 71, 217, 86, 119, 100, 210, 209,
        122, 207, 19, 72, 133, 165, 83, 254, 189, 41, 133, 53, 246, 211, 196,
        146, 88, 90, 57, 42, 20, 230, 79, 9, 242, 82, 167, 184, 87, 212, 109,
        164, 86,
      ],
      linkName: undefined,
      CountUpOptions: {
        useGrouping: false,
      },
      current_page: 1,
      total: 0,
      // 表格
      configTable: [
        {
          prop: "title",
          label: "批次名称",
          width: "154",
        },
        {
          prop: "SN",
          label: "批次号",
          width: "260",
        },
        {
          prop: "plot_title",
          label: "地块",
          width: "179",
        },
        {
          prop: "tx",
          label: "交易标志（tx）",
          width: "",
        },
        {
          prop: "created_time",
          label: "创建时间",
          width: "250",
        },
      ],
      tableData: [],
      line_personnel: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        legend: {
          data: [],
        },

        grid: {
          top: 100,
          left: 100,
          right: 20,
          bottom: 20,
        },
        backgroundColor: "transparent",
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: [],
          },
        ],
        yAxis: [
          {
            // name: "24小时交易量",
            nameGap: 30,
            nameLocation: "end",
            nameTextStyle: {
              color: "#fff",
              fontSize: 14,
              align: "right",
            },
            type: "value",
          },
        ],
        series: [],
      },

      statusCardList: [
        {
          img: require("../../assets/image/traceability/status1.png"),
          title: "全局时钟",
          data: 78389923,
          color: "rgba(3, 87, 173, .7)",
        },
        {
          img: require("../../assets/image/traceability/status2.png"),
          title: "交易总数",
          data: 18739229,
          color: "rgba(103, 194, 58, .7)",
        },
        {
          img: require("../../assets/image/traceability/status3.png"),
          title: "区块高度",
          data: 239879234,
          color: "rgba(0, 148, 203, 0.7)",
        },
        {
          img: require("../../assets/image/traceability/status4.png"),
          title: "当前纪元",
          data: 324,
          color: "rgba(0, 169, 151, .7)",
        },
      ],
    };
  },

  created() {
    if (!localStorage.getItem("initialize")) {
      this.getUplinkInitialize();
    }
    this.getLinkStatusInfo();
    this.getBatchListApi();
  },
  mounted() {
    this.getLinkChart();
  },
  computed: {
    ...mapState({
      farmID: (state) => state.user.farmID,
    }),
  },

  methods: {
    getLinkChart() {
      // let time_from = +moment().subtract(1, "days");
      // let time_to = +new Date();
      // time_from = "" + time_from;
      // time_from = time_from.slice(0, 10);
      // time_to = "" + time_to;
      // time_to = time_to.slice(0, 10);
      // const { data } = await getLinkChartApi({
      //   source: "all",
      //   type: "30m",
      //   chart: "total_volume24h",
      //   // chart: "total_tvl",
      //   time_from,
      //   time_to,
      //   cluster: "",
      // });

      const items = {
        phoenix: [
          {
            _id: "phoenix_total_volume24h_PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY_30m_1703734200",
            address: "PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T03:30:07.881Z",
            humanTime: "2023-12-28T03:30:00.000Z",
            source: "phoenix",
            symbol: "GMT",
            type: "30m",
            unixTime: 1703734200,
            updatedAt: "2023-12-28T03:59:29.366Z",
            value: 101473263.3450396,
          },
          {
            _id: "phoenix_total_volume24h_PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY_30m_1703736000",
            address: "PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T04:00:40.337Z",
            humanTime: "2023-12-28T04:00:00.000Z",
            source: "phoenix",
            symbol: "GMT",
            type: "30m",
            unixTime: 1703736000,
            updatedAt: "2023-12-28T04:28:59.526Z",
            value: 101502684.805853,
          },
          {
            _id: "phoenix_total_volume24h_PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY_30m_1703737800",
            address: "PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T04:30:11.032Z",
            humanTime: "2023-12-28T04:30:00.000Z",
            source: "phoenix",
            symbol: "GMT",
            type: "30m",
            unixTime: 1703737800,
            updatedAt: "2023-12-28T04:59:38.897Z",
            value: 100173238.07243545,
          },
          {
            _id: "phoenix_total_volume24h_PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY_30m_1703739600",
            address: "PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T05:00:48.978Z",
            humanTime: "2023-12-28T05:00:00.000Z",
            source: "phoenix",
            symbol: "GMT",
            type: "30m",
            unixTime: 1703739600,
            updatedAt: "2023-12-28T05:29:36.998Z",
            value: 100535990.39954023,
          },
          {
            _id: "phoenix_total_volume24h_PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY_30m_1703741400",
            address: "PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T05:30:58.363Z",
            humanTime: "2023-12-28T05:30:00.000Z",
            source: "phoenix",
            symbol: "GMT",
            type: "30m",
            unixTime: 1703741400,
            updatedAt: "2023-12-28T05:59:17.634Z",
            value: 100307975.6129341,
          },
          {
            _id: "phoenix_total_volume24h_PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY_30m_1703743200",
            address: "PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T06:00:28.761Z",
            humanTime: "2023-12-28T06:00:00.000Z",
            source: "phoenix",
            symbol: "GMT",
            type: "30m",
            unixTime: 1703743200,
            updatedAt: "2023-12-28T06:29:35.045Z",
            value: 100706807.61459118,
          },
          {
            _id: "phoenix_total_volume24h_PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY_30m_1703745000",
            address: "PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T06:30:46.638Z",
            humanTime: "2023-12-28T06:30:00.000Z",
            source: "phoenix",
            symbol: "GMT",
            type: "30m",
            unixTime: 1703745000,
            updatedAt: "2023-12-28T06:59:54.579Z",
            value: 101658574.41082048,
          },
          {
            _id: "phoenix_total_volume24h_PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY_30m_1703746800",
            address: "PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T07:01:08.550Z",
            humanTime: "2023-12-28T07:00:00.000Z",
            source: "phoenix",
            symbol: "GMT",
            type: "30m",
            unixTime: 1703746800,
            updatedAt: "2023-12-28T07:29:28.486Z",
            value: 103178361.87839502,
          },
          {
            _id: "phoenix_total_volume24h_PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY_30m_1703748600",
            address: "PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T07:30:50.083Z",
            humanTime: "2023-12-28T07:30:00.000Z",
            source: "phoenix",
            symbol: "GMT",
            type: "30m",
            unixTime: 1703748600,
            updatedAt: "2023-12-28T07:59:20.452Z",
            value: 104267603.01701073,
          },
          {
            _id: "phoenix_total_volume24h_PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY_30m_1703750400",
            address: "PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T08:00:32.281Z",
            humanTime: "2023-12-28T08:00:00.000Z",
            source: "phoenix",
            symbol: "GMT",
            type: "30m",
            unixTime: 1703750400,
            updatedAt: "2023-12-28T08:29:51.512Z",
            value: 105303924.1091574,
          },
          {
            _id: "phoenix_total_volume24h_PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY_30m_1703752200",
            address: "PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T08:31:06.726Z",
            humanTime: "2023-12-28T08:30:00.000Z",
            source: "phoenix",
            symbol: "GMT",
            type: "30m",
            unixTime: 1703752200,
            updatedAt: "2023-12-28T08:59:29.364Z",
            value: 105378536.54057872,
          },
          {
            _id: "phoenix_total_volume24h_PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY_30m_1703754000",
            address: "PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T09:01:08.283Z",
            humanTime: "2023-12-28T09:00:00.000Z",
            source: "phoenix",
            symbol: "GMT",
            type: "30m",
            unixTime: 1703754000,
            updatedAt: "2023-12-28T09:28:58.134Z",
            value: 105697637.39803039,
          },
          {
            _id: "phoenix_total_volume24h_PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY_30m_1703755800",
            address: "PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T09:30:14.165Z",
            humanTime: "2023-12-28T09:30:00.000Z",
            source: "phoenix",
            symbol: "GMT",
            type: "30m",
            unixTime: 1703755800,
            updatedAt: "2023-12-28T09:59:35.364Z",
            value: 106141499.9943457,
          },
          {
            _id: "phoenix_total_volume24h_PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY_30m_1703757600",
            address: "PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T10:00:46.311Z",
            humanTime: "2023-12-28T10:00:00.000Z",
            source: "phoenix",
            symbol: "GMT",
            type: "30m",
            unixTime: 1703757600,
            updatedAt: "2023-12-28T10:29:49.368Z",
            value: 106252199.25772448,
          },
          {
            _id: "phoenix_total_volume24h_PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY_30m_1703759400",
            address: "PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T10:31:45.806Z",
            humanTime: "2023-12-28T10:30:00.000Z",
            source: "phoenix",
            symbol: "GMT",
            type: "30m",
            unixTime: 1703759400,
            updatedAt: "2023-12-28T10:59:59.409Z",
            value: 106392381.32443637,
          },
          {
            _id: "phoenix_total_volume24h_PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY_30m_1703761200",
            address: "PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T11:01:18.998Z",
            humanTime: "2023-12-28T11:00:00.000Z",
            source: "phoenix",
            symbol: "GMT",
            type: "30m",
            unixTime: 1703761200,
            updatedAt: "2023-12-28T11:29:51.011Z",
            value: 105620250.09373103,
          },
          {
            _id: "phoenix_total_volume24h_PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY_30m_1703763000",
            address: "PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T11:31:06.632Z",
            humanTime: "2023-12-28T11:30:00.000Z",
            source: "phoenix",
            symbol: "GMT",
            type: "30m",
            unixTime: 1703763000,
            updatedAt: "2023-12-28T11:59:37.968Z",
            value: 105608134.20043202,
          },
          {
            _id: "phoenix_total_volume24h_PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY_30m_1703764800",
            address: "PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T12:00:55.118Z",
            humanTime: "2023-12-28T12:00:00.000Z",
            source: "phoenix",
            symbol: "GMT",
            type: "30m",
            unixTime: 1703764800,
            updatedAt: "2023-12-28T12:29:27.023Z",
            value: 105241484.79777806,
          },
          {
            _id: "phoenix_total_volume24h_PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY_30m_1703766600",
            address: "PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T12:30:39.217Z",
            humanTime: "2023-12-28T12:30:00.000Z",
            source: "phoenix",
            symbol: "GMT",
            type: "30m",
            unixTime: 1703766600,
            updatedAt: "2023-12-28T12:58:56.548Z",
            value: 106143342.1072398,
          },
          {
            _id: "phoenix_total_volume24h_PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY_30m_1703768400",
            address: "PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T13:00:43.570Z",
            humanTime: "2023-12-28T13:00:00.000Z",
            source: "phoenix",
            symbol: "GMT",
            type: "30m",
            unixTime: 1703768400,
            updatedAt: "2023-12-28T13:28:59.420Z",
            value: 108599341.47617042,
          },
          {
            _id: "phoenix_total_volume24h_PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY_30m_1703770200",
            address: "PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T13:30:10.836Z",
            humanTime: "2023-12-28T13:30:00.000Z",
            source: "phoenix",
            symbol: "GMT",
            type: "30m",
            unixTime: 1703770200,
            updatedAt: "2023-12-28T13:59:36.198Z",
            value: 109419553.30994302,
          },
          {
            _id: "phoenix_total_volume24h_PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY_30m_1703772000",
            address: "PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T14:00:52.504Z",
            humanTime: "2023-12-28T14:00:00.000Z",
            source: "phoenix",
            symbol: "GMT",
            type: "30m",
            unixTime: 1703772000,
            updatedAt: "2023-12-28T14:29:17.039Z",
            value: 109262774.57008007,
          },
          {
            _id: "phoenix_total_volume24h_PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY_30m_1703773800",
            address: "PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T14:30:31.516Z",
            humanTime: "2023-12-28T14:30:00.000Z",
            source: "phoenix",
            symbol: "GMT",
            type: "30m",
            unixTime: 1703773800,
            updatedAt: "2023-12-28T14:58:44.895Z",
            value: 109510126.04377949,
          },
          {
            _id: "phoenix_total_volume24h_PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY_30m_1703775600",
            address: "PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T15:00:07.467Z",
            humanTime: "2023-12-28T15:00:00.000Z",
            source: "phoenix",
            symbol: "GMT",
            type: "30m",
            unixTime: 1703775600,
            updatedAt: "2023-12-28T15:28:42.848Z",
            value: 110414696.6895721,
          },
          {
            _id: "phoenix_total_volume24h_PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY_30m_1703777400",
            address: "PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T15:30:55.379Z",
            humanTime: "2023-12-28T15:30:00.000Z",
            source: "phoenix",
            symbol: "GMT",
            type: "30m",
            unixTime: 1703777400,
            updatedAt: "2023-12-28T15:58:23.584Z",
            value: 111749793.34462991,
          },
          {
            _id: "phoenix_total_volume24h_PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY_30m_1703779200",
            address: "PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T16:00:15.227Z",
            humanTime: "2023-12-28T16:00:00.000Z",
            source: "phoenix",
            symbol: "GMT",
            type: "30m",
            unixTime: 1703779200,
            updatedAt: "2023-12-28T16:28:29.267Z",
            value: 112374432.16429974,
          },
          {
            _id: "phoenix_total_volume24h_PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY_30m_1703781000",
            address: "PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T16:30:30.466Z",
            humanTime: "2023-12-28T16:30:00.000Z",
            source: "phoenix",
            symbol: "GMT",
            type: "30m",
            unixTime: 1703781000,
            updatedAt: "2023-12-28T16:59:05.503Z",
            value: 110593069.54871082,
          },
          {
            _id: "phoenix_total_volume24h_PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY_30m_1703782800",
            address: "PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T17:00:56.512Z",
            humanTime: "2023-12-28T17:00:00.000Z",
            source: "phoenix",
            symbol: "GMT",
            type: "30m",
            unixTime: 1703782800,
            updatedAt: "2023-12-28T17:29:03.143Z",
            value: 112063329.93327606,
          },
          {
            _id: "phoenix_total_volume24h_PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY_30m_1703784600",
            address: "PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T17:31:21.109Z",
            humanTime: "2023-12-28T17:30:00.000Z",
            source: "phoenix",
            symbol: "GMT",
            type: "30m",
            unixTime: 1703784600,
            updatedAt: "2023-12-28T17:58:50.164Z",
            value: 112034897.18176855,
          },
          {
            _id: "phoenix_total_volume24h_PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY_30m_1703786400",
            address: "PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T18:00:00.151Z",
            humanTime: "2023-12-28T18:00:00.000Z",
            source: "phoenix",
            symbol: "GMT",
            type: "30m",
            unixTime: 1703786400,
            updatedAt: "2023-12-28T18:29:39.984Z",
            value: 111641392.72377731,
          },
          {
            _id: "phoenix_total_volume24h_PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY_30m_1703788200",
            address: "PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T18:30:50.739Z",
            humanTime: "2023-12-28T18:30:00.000Z",
            source: "phoenix",
            symbol: "GMT",
            type: "30m",
            unixTime: 1703788200,
            updatedAt: "2023-12-28T18:59:43.810Z",
            value: 111974529.03272572,
          },
          {
            _id: "phoenix_total_volume24h_PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY_30m_1703790000",
            address: "PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T19:01:00.116Z",
            humanTime: "2023-12-28T19:00:00.000Z",
            source: "phoenix",
            symbol: "GMT",
            type: "30m",
            unixTime: 1703790000,
            updatedAt: "2023-12-28T19:28:49.805Z",
            value: 112124625.9784056,
          },
          {
            _id: "phoenix_total_volume24h_PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY_30m_1703791800",
            address: "PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T19:30:08.789Z",
            humanTime: "2023-12-28T19:30:00.000Z",
            source: "phoenix",
            symbol: "GMT",
            type: "30m",
            unixTime: 1703791800,
            updatedAt: "2023-12-28T19:59:16.465Z",
            value: 111498305.80495808,
          },
          {
            _id: "phoenix_total_volume24h_PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY_30m_1703793600",
            address: "PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T20:00:27.548Z",
            humanTime: "2023-12-28T20:00:00.000Z",
            source: "phoenix",
            symbol: "GMT",
            type: "30m",
            unixTime: 1703793600,
            updatedAt: "2023-12-28T20:29:57.013Z",
            value: 112009035.13275808,
          },
          {
            _id: "phoenix_total_volume24h_PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY_30m_1703795400",
            address: "PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T20:31:05.539Z",
            humanTime: "2023-12-28T20:30:00.000Z",
            source: "phoenix",
            symbol: "GMT",
            type: "30m",
            unixTime: 1703795400,
            updatedAt: "2023-12-28T20:59:20.990Z",
            value: 112425554.24000627,
          },
          {
            _id: "phoenix_total_volume24h_PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY_30m_1703797200",
            address: "PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T21:00:29.478Z",
            humanTime: "2023-12-28T21:00:00.000Z",
            source: "phoenix",
            symbol: "GMT",
            type: "30m",
            unixTime: 1703797200,
            updatedAt: "2023-12-28T21:29:03.759Z",
            value: 113388478.80016977,
          },
          {
            _id: "phoenix_total_volume24h_PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY_30m_1703799000",
            address: "PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T21:30:14.165Z",
            humanTime: "2023-12-28T21:30:00.000Z",
            source: "phoenix",
            symbol: "GMT",
            type: "30m",
            unixTime: 1703799000,
            updatedAt: "2023-12-28T21:59:52.768Z",
            value: 114232403.93952309,
          },
          {
            _id: "phoenix_total_volume24h_PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY_30m_1703800800",
            address: "PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T22:01:04.257Z",
            humanTime: "2023-12-28T22:00:00.000Z",
            source: "phoenix",
            symbol: "GMT",
            type: "30m",
            unixTime: 1703800800,
            updatedAt: "2023-12-28T22:29:04.250Z",
            value: 114314398.93924186,
          },
          {
            _id: "phoenix_total_volume24h_PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY_30m_1703802600",
            address: "PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T22:30:26.916Z",
            humanTime: "2023-12-28T22:30:00.000Z",
            source: "phoenix",
            symbol: "GMT",
            type: "30m",
            unixTime: 1703802600,
            updatedAt: "2023-12-28T22:59:52.244Z",
            value: 115172020.13505954,
          },
          {
            _id: "phoenix_total_volume24h_PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY_30m_1703804400",
            address: "PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T23:01:06.809Z",
            humanTime: "2023-12-28T23:00:00.000Z",
            source: "phoenix",
            symbol: "GMT",
            type: "30m",
            unixTime: 1703804400,
            updatedAt: "2023-12-28T23:29:33.054Z",
            value: 113992722.13733256,
          },
          {
            _id: "phoenix_total_volume24h_PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY_30m_1703806200",
            address: "PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T23:30:44.060Z",
            humanTime: "2023-12-28T23:30:00.000Z",
            source: "phoenix",
            symbol: "GMT",
            type: "30m",
            unixTime: 1703806200,
            updatedAt: "2023-12-28T23:59:57.303Z",
            value: 114320755.78253035,
          },
          {
            _id: "phoenix_total_volume24h_PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY_30m_1703808000",
            address: "PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY",
            chartName: "total_volume24h",
            createdAt: "2023-12-29T00:01:10.197Z",
            humanTime: "2023-12-29T00:00:00.000Z",
            source: "phoenix",
            symbol: "GMT",
            type: "30m",
            unixTime: 1703808000,
            updatedAt: "2023-12-29T00:29:35.491Z",
            value: 114610356.86093521,
          },
          {
            _id: "phoenix_total_volume24h_PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY_30m_1703809800",
            address: "PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY",
            chartName: "total_volume24h",
            createdAt: "2023-12-29T00:30:44.022Z",
            humanTime: "2023-12-29T00:30:00.000Z",
            source: "phoenix",
            symbol: "GMT",
            type: "30m",
            unixTime: 1703809800,
            updatedAt: "2023-12-29T00:59:46.185Z",
            value: 115769223.87741813,
          },
          {
            _id: "phoenix_total_volume24h_PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY_30m_1703811600",
            address: "PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY",
            chartName: "total_volume24h",
            createdAt: "2023-12-29T01:00:53.933Z",
            humanTime: "2023-12-29T01:00:00.000Z",
            source: "phoenix",
            symbol: "GMT",
            type: "30m",
            unixTime: 1703811600,
            updatedAt: "2023-12-29T01:29:46.372Z",
            value: 115311188.8102779,
          },
          {
            _id: "phoenix_total_volume24h_PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY_30m_1703813400",
            address: "PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY",
            chartName: "total_volume24h",
            createdAt: "2023-12-29T01:30:57.604Z",
            humanTime: "2023-12-29T01:30:00.000Z",
            source: "phoenix",
            symbol: "GMT",
            type: "30m",
            unixTime: 1703813400,
            updatedAt: "2023-12-29T01:59:25.210Z",
            value: 113654909.92595002,
          },
          {
            _id: "phoenix_total_volume24h_PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY_30m_1703815200",
            address: "PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY",
            chartName: "total_volume24h",
            createdAt: "2023-12-29T02:00:35.624Z",
            humanTime: "2023-12-29T02:00:00.000Z",
            source: "phoenix",
            symbol: "GMT",
            type: "30m",
            unixTime: 1703815200,
            updatedAt: "2023-12-29T02:29:12.810Z",
            value: 113106584.1892311,
          },
          {
            _id: "phoenix_total_volume24h_PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY_30m_1703817000",
            address: "PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY",
            chartName: "total_volume24h",
            createdAt: "2023-12-29T02:30:44.077Z",
            humanTime: "2023-12-29T02:30:00.000Z",
            source: "phoenix",
            symbol: "GMT",
            type: "30m",
            unixTime: 1703817000,
            updatedAt: "2023-12-29T02:58:50.016Z",
            value: 114418248.05215397,
          },
          {
            _id: "phoenix_total_volume24h_PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY_30m_1703818800",
            address: "PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY",
            chartName: "total_volume24h",
            createdAt: "2023-12-29T03:00:04.863Z",
            humanTime: "2023-12-29T03:00:00.000Z",
            source: "phoenix",
            symbol: "GMT",
            type: "30m",
            unixTime: 1703818800,
            updatedAt: "2023-12-29T03:18:49.962Z",
            value: 114682819.81474409,
          },
        ],
        solend: [
          {
            _id: "solend_total_volume24h_solend_30m_1703734200",
            address: "solend",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T03:31:28.247Z",
            humanTime: "2023-12-28T03:30:00.000Z",
            source: "solend",
            symbol: null,
            type: "30m",
            unixTime: 1703734200,
            updatedAt: "2023-12-28T03:59:08.300Z",
            value: 27775579.796439093,
          },
          {
            _id: "solend_total_volume24h_solend_30m_1703736000",
            address: "solend",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T04:02:58.311Z",
            humanTime: "2023-12-28T04:00:00.000Z",
            source: "solend",
            symbol: null,
            type: "30m",
            unixTime: 1703736000,
            updatedAt: "2023-12-28T04:26:48.338Z",
            value: 27988999.829604365,
          },
          {
            _id: "solend_total_volume24h_solend_30m_1703737800",
            address: "solend",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T04:31:08.348Z",
            humanTime: "2023-12-28T04:30:00.000Z",
            source: "solend",
            symbol: null,
            type: "30m",
            unixTime: 1703737800,
            updatedAt: "2023-12-28T04:56:18.406Z",
            value: 27482538.81032184,
          },
          {
            _id: "solend_total_volume24h_solend_30m_1703739600",
            address: "solend",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T05:00:38.411Z",
            humanTime: "2023-12-28T05:00:00.000Z",
            source: "solend",
            symbol: null,
            type: "30m",
            unixTime: 1703739600,
            updatedAt: "2023-12-28T05:28:58.463Z",
            value: 27447574.60859821,
          },
          {
            _id: "solend_total_volume24h_solend_30m_1703741400",
            address: "solend",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T05:32:58.471Z",
            humanTime: "2023-12-28T05:30:00.000Z",
            source: "solend",
            symbol: null,
            type: "30m",
            unixTime: 1703741400,
            updatedAt: "2023-12-28T05:57:18.529Z",
            value: 26872398.461501658,
          },
          {
            _id: "solend_total_volume24h_solend_30m_1703743200",
            address: "solend",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T06:01:18.538Z",
            humanTime: "2023-12-28T06:00:00.000Z",
            source: "solend",
            symbol: null,
            type: "30m",
            unixTime: 1703743200,
            updatedAt: "2023-12-28T06:28:18.574Z",
            value: 26362289.06219314,
          },
          {
            _id: "solend_total_volume24h_solend_30m_1703745000",
            address: "solend",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T06:31:58.584Z",
            humanTime: "2023-12-28T06:30:00.000Z",
            source: "solend",
            symbol: null,
            type: "30m",
            unixTime: 1703745000,
            updatedAt: "2023-12-28T06:58:28.632Z",
            value: 26411382.408300973,
          },
          {
            _id: "solend_total_volume24h_solend_30m_1703746800",
            address: "solend",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T07:02:38.637Z",
            humanTime: "2023-12-28T07:00:00.000Z",
            source: "solend",
            symbol: null,
            type: "30m",
            unixTime: 1703746800,
            updatedAt: "2023-12-28T07:27:18.687Z",
            value: 26509548.905712765,
          },
          {
            _id: "solend_total_volume24h_solend_30m_1703748600",
            address: "solend",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T07:35:08.692Z",
            humanTime: "2023-12-28T07:30:00.000Z",
            source: "solend",
            symbol: null,
            type: "30m",
            unixTime: 1703748600,
            updatedAt: "2023-12-28T07:59:08.731Z",
            value: 27718486.224499647,
          },
          {
            _id: "solend_total_volume24h_solend_30m_1703750400",
            address: "solend",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T08:03:08.738Z",
            humanTime: "2023-12-28T08:00:00.000Z",
            source: "solend",
            symbol: null,
            type: "30m",
            unixTime: 1703750400,
            updatedAt: "2023-12-28T08:27:08.768Z",
            value: 27957882.536850207,
          },
          {
            _id: "solend_total_volume24h_solend_30m_1703752200",
            address: "solend",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T08:31:08.768Z",
            humanTime: "2023-12-28T08:30:00.000Z",
            source: "solend",
            symbol: null,
            type: "30m",
            unixTime: 1703752200,
            updatedAt: "2023-12-28T08:58:58.812Z",
            value: 27874863.655805774,
          },
          {
            _id: "solend_total_volume24h_solend_30m_1703754000",
            address: "solend",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T09:02:38.819Z",
            humanTime: "2023-12-28T09:00:00.000Z",
            source: "solend",
            symbol: null,
            type: "30m",
            unixTime: 1703754000,
            updatedAt: "2023-12-28T09:27:38.866Z",
            value: 27894111.066705108,
          },
          {
            _id: "solend_total_volume24h_solend_30m_1703755800",
            address: "solend",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T09:31:38.876Z",
            humanTime: "2023-12-28T09:30:00.000Z",
            source: "solend",
            symbol: null,
            type: "30m",
            unixTime: 1703755800,
            updatedAt: "2023-12-28T09:57:08.905Z",
            value: 27838575.33091305,
          },
          {
            _id: "solend_total_volume24h_solend_30m_1703757600",
            address: "solend",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T10:01:18.910Z",
            humanTime: "2023-12-28T10:00:00.000Z",
            source: "solend",
            symbol: null,
            type: "30m",
            unixTime: 1703757600,
            updatedAt: "2023-12-28T10:29:48.940Z",
            value: 27369357.49551761,
          },
          {
            _id: "solend_total_volume24h_solend_30m_1703759400",
            address: "solend",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T10:33:48.943Z",
            humanTime: "2023-12-28T10:30:00.000Z",
            source: "solend",
            symbol: null,
            type: "30m",
            unixTime: 1703759400,
            updatedAt: "2023-12-28T10:59:28.970Z",
            value: 26019301.16375895,
          },
          {
            _id: "solend_total_volume24h_solend_30m_1703761200",
            address: "solend",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T11:03:28.971Z",
            humanTime: "2023-12-28T11:00:00.000Z",
            source: "solend",
            symbol: null,
            type: "30m",
            unixTime: 1703761200,
            updatedAt: "2023-12-28T11:28:18.997Z",
            value: 25229029.901994728,
          },
          {
            _id: "solend_total_volume24h_solend_30m_1703763000",
            address: "solend",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T11:32:38.999Z",
            humanTime: "2023-12-28T11:30:00.000Z",
            source: "solend",
            symbol: null,
            type: "30m",
            unixTime: 1703763000,
            updatedAt: "2023-12-28T11:57:29.024Z",
            value: 26229413.02984548,
          },
          {
            _id: "solend_total_volume24h_solend_30m_1703764800",
            address: "solend",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T12:01:39.029Z",
            humanTime: "2023-12-28T12:00:00.000Z",
            source: "solend",
            symbol: null,
            type: "30m",
            unixTime: 1703764800,
            updatedAt: "2023-12-28T12:27:29.074Z",
            value: 26333865.10630937,
          },
          {
            _id: "solend_total_volume24h_solend_30m_1703766600",
            address: "solend",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T12:31:39.082Z",
            humanTime: "2023-12-28T12:30:00.000Z",
            source: "solend",
            symbol: null,
            type: "30m",
            unixTime: 1703766600,
            updatedAt: "2023-12-28T12:56:39.123Z",
            value: 25542095.550519906,
          },
          {
            _id: "solend_total_volume24h_solend_30m_1703768400",
            address: "solend",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T13:04:19.135Z",
            humanTime: "2023-12-28T13:00:00.000Z",
            source: "solend",
            symbol: null,
            type: "30m",
            unixTime: 1703768400,
            updatedAt: "2023-12-28T13:26:19.172Z",
            value: 25486337.683416173,
          },
          {
            _id: "solend_total_volume24h_solend_30m_1703770200",
            address: "solend",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T13:30:29.179Z",
            humanTime: "2023-12-28T13:30:00.000Z",
            source: "solend",
            symbol: null,
            type: "30m",
            unixTime: 1703770200,
            updatedAt: "2023-12-28T13:55:49.198Z",
            value: 25985995.695043705,
          },
          {
            _id: "solend_total_volume24h_solend_30m_1703772000",
            address: "solend",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T14:00:09.201Z",
            humanTime: "2023-12-28T14:00:00.000Z",
            source: "solend",
            symbol: null,
            type: "30m",
            unixTime: 1703772000,
            updatedAt: "2023-12-28T14:25:49.223Z",
            value: 26734329.43929869,
          },
          {
            _id: "solend_total_volume24h_solend_30m_1703773800",
            address: "solend",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T14:30:09.228Z",
            humanTime: "2023-12-28T14:30:00.000Z",
            source: "solend",
            symbol: null,
            type: "30m",
            unixTime: 1703773800,
            updatedAt: "2023-12-28T14:58:29.271Z",
            value: 25999359.663742293,
          },
          {
            _id: "solend_total_volume24h_solend_30m_1703775600",
            address: "solend",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T15:02:49.279Z",
            humanTime: "2023-12-28T15:00:00.000Z",
            source: "solend",
            symbol: null,
            type: "30m",
            unixTime: 1703775600,
            updatedAt: "2023-12-28T15:27:49.323Z",
            value: 26564828.869686056,
          },
          {
            _id: "solend_total_volume24h_solend_30m_1703777400",
            address: "solend",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T15:31:39.330Z",
            humanTime: "2023-12-28T15:30:00.000Z",
            source: "solend",
            symbol: null,
            type: "30m",
            unixTime: 1703777400,
            updatedAt: "2023-12-28T15:59:29.365Z",
            value: 26547966.447237223,
          },
          {
            _id: "solend_total_volume24h_solend_30m_1703779200",
            address: "solend",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T16:03:39.371Z",
            humanTime: "2023-12-28T16:00:00.000Z",
            source: "solend",
            symbol: null,
            type: "30m",
            unixTime: 1703779200,
            updatedAt: "2023-12-28T16:29:49.435Z",
            value: 26703633.081575282,
          },
          {
            _id: "solend_total_volume24h_solend_30m_1703781000",
            address: "solend",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T16:33:39.444Z",
            humanTime: "2023-12-28T16:30:00.000Z",
            source: "solend",
            symbol: null,
            type: "30m",
            unixTime: 1703781000,
            updatedAt: "2023-12-28T16:58:59.477Z",
            value: 27013195.408248723,
          },
          {
            _id: "solend_total_volume24h_solend_30m_1703782800",
            address: "solend",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T17:03:09.485Z",
            humanTime: "2023-12-28T17:00:00.000Z",
            source: "solend",
            symbol: null,
            type: "30m",
            unixTime: 1703782800,
            updatedAt: "2023-12-28T17:28:49.536Z",
            value: 26560537.943551138,
          },
          {
            _id: "solend_total_volume24h_solend_30m_1703784600",
            address: "solend",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T17:32:59.544Z",
            humanTime: "2023-12-28T17:30:00.000Z",
            source: "solend",
            symbol: null,
            type: "30m",
            unixTime: 1703784600,
            updatedAt: "2023-12-28T17:57:59.576Z",
            value: 26648161.815088958,
          },
          {
            _id: "solend_total_volume24h_solend_30m_1703786400",
            address: "solend",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T18:02:09.585Z",
            humanTime: "2023-12-28T18:00:00.000Z",
            source: "solend",
            symbol: null,
            type: "30m",
            unixTime: 1703786400,
            updatedAt: "2023-12-28T18:28:39.632Z",
            value: 26753853.273937654,
          },
          {
            _id: "solend_total_volume24h_solend_30m_1703788200",
            address: "solend",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T18:32:59.647Z",
            humanTime: "2023-12-28T18:30:00.000Z",
            source: "solend",
            symbol: null,
            type: "30m",
            unixTime: 1703788200,
            updatedAt: "2023-12-28T18:58:19.680Z",
            value: 26506605.777954817,
          },
          {
            _id: "solend_total_volume24h_solend_30m_1703790000",
            address: "solend",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T19:02:29.685Z",
            humanTime: "2023-12-28T19:00:00.000Z",
            source: "solend",
            symbol: null,
            type: "30m",
            unixTime: 1703790000,
            updatedAt: "2023-12-28T19:27:49.730Z",
            value: 27148199.088303816,
          },
          {
            _id: "solend_total_volume24h_solend_30m_1703791800",
            address: "solend",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T19:31:59.736Z",
            humanTime: "2023-12-28T19:30:00.000Z",
            source: "solend",
            symbol: null,
            type: "30m",
            unixTime: 1703791800,
            updatedAt: "2023-12-28T19:57:49.772Z",
            value: 27415834.67683917,
          },
          {
            _id: "solend_total_volume24h_solend_30m_1703793600",
            address: "solend",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T20:01:59.779Z",
            humanTime: "2023-12-28T20:00:00.000Z",
            source: "solend",
            symbol: null,
            type: "30m",
            unixTime: 1703793600,
            updatedAt: "2023-12-28T20:22:39.819Z",
            value: 27362513.419104587,
          },
          {
            _id: "solend_total_volume24h_solend_30m_1703795400",
            address: "solend",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T20:30:49.832Z",
            humanTime: "2023-12-28T20:30:00.000Z",
            source: "solend",
            symbol: null,
            type: "30m",
            unixTime: 1703795400,
            updatedAt: "2023-12-28T20:55:49.866Z",
            value: 26803319.64687451,
          },
          {
            _id: "solend_total_volume24h_solend_30m_1703797200",
            address: "solend",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T21:03:49.873Z",
            humanTime: "2023-12-28T21:00:00.000Z",
            source: "solend",
            symbol: null,
            type: "30m",
            unixTime: 1703797200,
            updatedAt: "2023-12-28T21:28:09.924Z",
            value: 26795416.85817828,
          },
          {
            _id: "solend_total_volume24h_solend_30m_1703799000",
            address: "solend",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T21:32:19.929Z",
            humanTime: "2023-12-28T21:30:00.000Z",
            source: "solend",
            symbol: null,
            type: "30m",
            unixTime: 1703799000,
            updatedAt: "2023-12-28T21:57:29.985Z",
            value: 27010537.04958202,
          },
          {
            _id: "solend_total_volume24h_solend_30m_1703800800",
            address: "solend",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T22:01:39.989Z",
            humanTime: "2023-12-28T22:00:00.000Z",
            source: "solend",
            symbol: null,
            type: "30m",
            unixTime: 1703800800,
            updatedAt: "2023-12-28T22:30:00.040Z",
            value: 26968515.14696292,
          },
          {
            _id: "solend_total_volume24h_solend_30m_1703802600",
            address: "solend",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T22:33:50.049Z",
            humanTime: "2023-12-28T22:30:00.000Z",
            source: "solend",
            symbol: null,
            type: "30m",
            unixTime: 1703802600,
            updatedAt: "2023-12-28T22:59:00.096Z",
            value: 28876607.409170985,
          },
          {
            _id: "solend_total_volume24h_solend_30m_1703804400",
            address: "solend",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T23:03:10.101Z",
            humanTime: "2023-12-28T23:00:00.000Z",
            source: "solend",
            symbol: null,
            type: "30m",
            unixTime: 1703804400,
            updatedAt: "2023-12-28T23:29:20.136Z",
            value: 26476196.559055425,
          },
          {
            _id: "solend_total_volume24h_solend_30m_1703806200",
            address: "solend",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T23:33:40.147Z",
            humanTime: "2023-12-28T23:30:00.000Z",
            source: "solend",
            symbol: null,
            type: "30m",
            unixTime: 1703806200,
            updatedAt: "2023-12-28T23:59:50.189Z",
            value: 26644229.252828393,
          },
          {
            _id: "solend_total_volume24h_solend_30m_1703808000",
            address: "solend",
            chartName: "total_volume24h",
            createdAt: "2023-12-29T00:07:50.199Z",
            humanTime: "2023-12-29T00:00:00.000Z",
            source: "solend",
            symbol: null,
            type: "30m",
            unixTime: 1703808000,
            updatedAt: "2023-12-29T00:26:30.231Z",
            value: 26403224.47218765,
          },
          {
            _id: "solend_total_volume24h_solend_30m_1703809800",
            address: "solend",
            chartName: "total_volume24h",
            createdAt: "2023-12-29T00:30:40.237Z",
            humanTime: "2023-12-29T00:30:00.000Z",
            source: "solend",
            symbol: null,
            type: "30m",
            unixTime: 1703809800,
            updatedAt: "2023-12-29T00:56:30.291Z",
            value: 25840063.291826103,
          },
          {
            _id: "solend_total_volume24h_solend_30m_1703811600",
            address: "solend",
            chartName: "total_volume24h",
            createdAt: "2023-12-29T01:00:10.296Z",
            humanTime: "2023-12-29T01:00:00.000Z",
            source: "solend",
            symbol: null,
            type: "30m",
            unixTime: 1703811600,
            updatedAt: "2023-12-29T01:29:00.339Z",
            value: 25303476.70786218,
          },
          {
            _id: "solend_total_volume24h_solend_30m_1703813400",
            address: "solend",
            chartName: "total_volume24h",
            createdAt: "2023-12-29T01:33:20.343Z",
            humanTime: "2023-12-29T01:30:00.000Z",
            source: "solend",
            symbol: null,
            type: "30m",
            unixTime: 1703813400,
            updatedAt: "2023-12-29T01:58:00.395Z",
            value: 24487461.957806036,
          },
          {
            _id: "solend_total_volume24h_solend_30m_1703815200",
            address: "solend",
            chartName: "total_volume24h",
            createdAt: "2023-12-29T02:02:00.403Z",
            humanTime: "2023-12-29T02:00:00.000Z",
            source: "solend",
            symbol: null,
            type: "30m",
            unixTime: 1703815200,
            updatedAt: "2023-12-29T02:27:00.434Z",
            value: 18925806.990743987,
          },
          {
            _id: "solend_total_volume24h_solend_30m_1703817000",
            address: "solend",
            chartName: "total_volume24h",
            createdAt: "2023-12-29T02:31:00.444Z",
            humanTime: "2023-12-29T02:30:00.000Z",
            source: "solend",
            symbol: null,
            type: "30m",
            unixTime: 1703817000,
            updatedAt: "2023-12-29T02:56:10.495Z",
            value: 17733638.727031454,
          },
          {
            _id: "solend_total_volume24h_solend_30m_1703818800",
            address: "solend",
            chartName: "total_volume24h",
            createdAt: "2023-12-29T03:00:10.507Z",
            humanTime: "2023-12-29T03:00:00.000Z",
            source: "solend",
            symbol: null,
            type: "30m",
            unixTime: 1703818800,
            updatedAt: "2023-12-29T03:16:10.527Z",
            value: 18543981.9745158,
          },
        ],
        zeta: [
          {
            _id: "zeta_total_volume24h_undefined_30m_1703734200",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T03:30:39.404Z",
            humanTime: "2023-12-28T03:30:00.000Z",
            source: "zeta",
            type: "30m",
            unixTime: 1703734200,
            updatedAt: "2023-12-28T04:00:00.100Z",
            value: 33689250.545468,
          },
          {
            _id: "zeta_total_volume24h_undefined_30m_1703736000",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T04:00:10.565Z",
            humanTime: "2023-12-28T04:00:00.000Z",
            source: "zeta",
            type: "30m",
            unixTime: 1703736000,
            updatedAt: "2023-12-28T04:29:36.433Z",
            value: 33954442.346783,
          },
          {
            _id: "zeta_total_volume24h_undefined_30m_1703737800",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T04:30:41.675Z",
            humanTime: "2023-12-28T04:30:00.000Z",
            source: "zeta",
            type: "30m",
            unixTime: 1703737800,
            updatedAt: "2023-12-28T04:58:57.912Z",
            value: 34024663.489081,
          },
          {
            _id: "zeta_total_volume24h_undefined_30m_1703739600",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T05:00:09.251Z",
            humanTime: "2023-12-28T05:00:00.000Z",
            source: "zeta",
            type: "30m",
            unixTime: 1703739600,
            updatedAt: "2023-12-28T05:29:39.504Z",
            value: 34251357.483211,
          },
          {
            _id: "zeta_total_volume24h_undefined_30m_1703741400",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T05:30:46.100Z",
            humanTime: "2023-12-28T05:30:00.000Z",
            source: "zeta",
            type: "30m",
            unixTime: 1703741400,
            updatedAt: "2023-12-28T05:59:02.612Z",
            value: 34411783.02097,
          },
          {
            _id: "zeta_total_volume24h_undefined_30m_1703743200",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T06:00:09.206Z",
            humanTime: "2023-12-28T06:00:00.000Z",
            source: "zeta",
            type: "30m",
            unixTime: 1703743200,
            updatedAt: "2023-12-28T06:29:35.045Z",
            value: 34225829.146582,
          },
          {
            _id: "zeta_total_volume24h_undefined_30m_1703745000",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T06:30:46.638Z",
            humanTime: "2023-12-28T06:30:00.000Z",
            source: "zeta",
            type: "30m",
            unixTime: 1703745000,
            updatedAt: "2023-12-28T06:59:54.579Z",
            value: 34306493.70418,
          },
          {
            _id: "zeta_total_volume24h_undefined_30m_1703746800",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T07:00:08.740Z",
            humanTime: "2023-12-28T07:00:00.000Z",
            source: "zeta",
            type: "30m",
            unixTime: 1703746800,
            updatedAt: "2023-12-28T07:29:34.224Z",
            value: 34734389.551658,
          },
          {
            _id: "zeta_total_volume24h_undefined_30m_1703748600",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T07:30:47.406Z",
            humanTime: "2023-12-28T07:30:00.000Z",
            source: "zeta",
            type: "30m",
            unixTime: 1703748600,
            updatedAt: "2023-12-28T07:59:08.131Z",
            value: 35292056.180081,
          },
          {
            _id: "zeta_total_volume24h_undefined_30m_1703750400",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T08:00:09.297Z",
            humanTime: "2023-12-28T08:00:00.000Z",
            source: "zeta",
            type: "30m",
            unixTime: 1703750400,
            updatedAt: "2023-12-28T08:29:51.512Z",
            value: 35428281.701415,
          },
          {
            _id: "zeta_total_volume24h_undefined_30m_1703752200",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T08:30:58.992Z",
            humanTime: "2023-12-28T08:30:00.000Z",
            source: "zeta",
            type: "30m",
            unixTime: 1703752200,
            updatedAt: "2023-12-28T08:58:53.744Z",
            value: 34477612.438179,
          },
          {
            _id: "zeta_total_volume24h_undefined_30m_1703754000",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T09:00:07.938Z",
            humanTime: "2023-12-28T09:00:00.000Z",
            source: "zeta",
            type: "30m",
            unixTime: 1703754000,
            updatedAt: "2023-12-28T09:29:52.555Z",
            value: 34279403.318859,
          },
          {
            _id: "zeta_total_volume24h_undefined_30m_1703755800",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T09:31:02.003Z",
            humanTime: "2023-12-28T09:30:00.000Z",
            source: "zeta",
            type: "30m",
            unixTime: 1703755800,
            updatedAt: "2023-12-28T09:59:35.364Z",
            value: 32274360.995373,
          },
          {
            _id: "zeta_total_volume24h_undefined_30m_1703757600",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T10:00:09.491Z",
            humanTime: "2023-12-28T10:00:00.000Z",
            source: "zeta",
            type: "30m",
            unixTime: 1703757600,
            updatedAt: "2023-12-28T10:29:12.888Z",
            value: 31970590.856584,
          },
          {
            _id: "zeta_total_volume24h_undefined_30m_1703759400",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T10:30:29.890Z",
            humanTime: "2023-12-28T10:30:00.000Z",
            source: "zeta",
            type: "30m",
            unixTime: 1703759400,
            updatedAt: "2023-12-28T10:59:58.859Z",
            value: 31757908.76853,
          },
          {
            _id: "zeta_total_volume24h_undefined_30m_1703761200",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T11:00:08.808Z",
            humanTime: "2023-12-28T11:00:00.000Z",
            source: "zeta",
            type: "30m",
            unixTime: 1703761200,
            updatedAt: "2023-12-28T11:29:00.607Z",
            value: 31127429.866613,
          },
          {
            _id: "zeta_total_volume24h_undefined_30m_1703763000",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T11:30:08.807Z",
            humanTime: "2023-12-28T11:30:00.000Z",
            source: "zeta",
            type: "30m",
            unixTime: 1703763000,
            updatedAt: "2023-12-28T11:59:02.513Z",
            value: 30588717.096011,
          },
          {
            _id: "zeta_total_volume24h_undefined_30m_1703764800",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T12:00:10.566Z",
            humanTime: "2023-12-28T12:00:00.000Z",
            source: "zeta",
            type: "30m",
            unixTime: 1703764800,
            updatedAt: "2023-12-28T12:29:19.705Z",
            value: 30541759.623734,
          },
          {
            _id: "zeta_total_volume24h_undefined_30m_1703766600",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T12:30:25.162Z",
            humanTime: "2023-12-28T12:30:00.000Z",
            source: "zeta",
            type: "30m",
            unixTime: 1703766600,
            updatedAt: "2023-12-28T12:58:56.548Z",
            value: 39004937.558366,
          },
          {
            _id: "zeta_total_volume24h_undefined_30m_1703768400",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T13:00:10.838Z",
            humanTime: "2023-12-28T13:00:00.000Z",
            source: "zeta",
            type: "30m",
            unixTime: 1703768400,
            updatedAt: "2023-12-28T13:29:14.898Z",
            value: 39467483.631028,
          },
          {
            _id: "zeta_total_volume24h_undefined_30m_1703770200",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T13:30:21.328Z",
            humanTime: "2023-12-28T13:30:00.000Z",
            source: "zeta",
            type: "30m",
            unixTime: 1703770200,
            updatedAt: "2023-12-28T13:59:13.729Z",
            value: 39651730.847894,
          },
          {
            _id: "zeta_total_volume24h_undefined_30m_1703772000",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T14:00:13.771Z",
            humanTime: "2023-12-28T14:00:00.000Z",
            source: "zeta",
            type: "30m",
            unixTime: 1703772000,
            updatedAt: "2023-12-28T14:29:27.834Z",
            value: 40075258.51651,
          },
          {
            _id: "zeta_total_volume24h_undefined_30m_1703773800",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T14:30:32.323Z",
            humanTime: "2023-12-28T14:30:00.000Z",
            source: "zeta",
            type: "30m",
            unixTime: 1703773800,
            updatedAt: "2023-12-28T14:59:28.831Z",
            value: 40191676.384016,
          },
          {
            _id: "zeta_total_volume24h_undefined_30m_1703775600",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T15:00:08.384Z",
            humanTime: "2023-12-28T15:00:00.000Z",
            source: "zeta",
            type: "30m",
            unixTime: 1703775600,
            updatedAt: "2023-12-28T15:29:49.293Z",
            value: 40176633.321184,
          },
          {
            _id: "zeta_total_volume24h_undefined_30m_1703777400",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T15:31:05.759Z",
            humanTime: "2023-12-28T15:30:00.000Z",
            source: "zeta",
            type: "30m",
            unixTime: 1703777400,
            updatedAt: "2023-12-28T15:59:04.587Z",
            value: 40565645.573925,
          },
          {
            _id: "zeta_total_volume24h_undefined_30m_1703779200",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T16:00:15.227Z",
            humanTime: "2023-12-28T16:00:00.000Z",
            source: "zeta",
            type: "30m",
            unixTime: 1703779200,
            updatedAt: "2023-12-28T16:29:32.876Z",
            value: 40598490.838279,
          },
          {
            _id: "zeta_total_volume24h_undefined_30m_1703781000",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T16:30:51.477Z",
            humanTime: "2023-12-28T16:30:00.000Z",
            source: "zeta",
            type: "30m",
            unixTime: 1703781000,
            updatedAt: "2023-12-28T16:59:23.511Z",
            value: 40044426.728484,
          },
          {
            _id: "zeta_total_volume24h_undefined_30m_1703782800",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T17:00:11.683Z",
            humanTime: "2023-12-28T17:00:00.000Z",
            source: "zeta",
            type: "30m",
            unixTime: 1703782800,
            updatedAt: "2023-12-28T17:29:47.135Z",
            value: 39999514.437407,
          },
          {
            _id: "zeta_total_volume24h_undefined_30m_1703784600",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T17:31:07.776Z",
            humanTime: "2023-12-28T17:30:00.000Z",
            source: "zeta",
            type: "30m",
            unixTime: 1703784600,
            updatedAt: "2023-12-28T17:59:28.067Z",
            value: 39817663.844371,
          },
          {
            _id: "zeta_total_volume24h_undefined_30m_1703786400",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T18:00:08.602Z",
            humanTime: "2023-12-28T18:00:00.000Z",
            source: "zeta",
            type: "30m",
            unixTime: 1703786400,
            updatedAt: "2023-12-28T18:29:51.924Z",
            value: 39720156.322716,
          },
          {
            _id: "zeta_total_volume24h_undefined_30m_1703788200",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T18:30:58.505Z",
            humanTime: "2023-12-28T18:30:00.000Z",
            source: "zeta",
            type: "30m",
            unixTime: 1703788200,
            updatedAt: "2023-12-28T18:59:32.818Z",
            value: 39763217.741304,
          },
          {
            _id: "zeta_total_volume24h_undefined_30m_1703790000",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T19:00:08.112Z",
            humanTime: "2023-12-28T19:00:00.000Z",
            source: "zeta",
            type: "30m",
            unixTime: 1703790000,
            updatedAt: "2023-12-28T19:28:58.253Z",
            value: 39714591.027528,
          },
          {
            _id: "zeta_total_volume24h_undefined_30m_1703791800",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T19:30:08.789Z",
            humanTime: "2023-12-28T19:30:00.000Z",
            source: "zeta",
            type: "30m",
            unixTime: 1703791800,
            updatedAt: "2023-12-28T19:59:53.317Z",
            value: 40012042.277763,
          },
          {
            _id: "zeta_total_volume24h_undefined_30m_1703793600",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T20:00:10.278Z",
            humanTime: "2023-12-28T20:00:00.000Z",
            source: "zeta",
            type: "30m",
            unixTime: 1703793600,
            updatedAt: "2023-12-28T20:29:37.230Z",
            value: 40248172.747916,
          },
          {
            _id: "zeta_total_volume24h_undefined_30m_1703795400",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T20:30:43.384Z",
            humanTime: "2023-12-28T20:30:00.000Z",
            source: "zeta",
            type: "30m",
            unixTime: 1703795400,
            updatedAt: "2023-12-28T20:59:01.230Z",
            value: 39974772.034503,
          },
          {
            _id: "zeta_total_volume24h_undefined_30m_1703797200",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T21:00:09.817Z",
            humanTime: "2023-12-28T21:00:00.000Z",
            source: "zeta",
            type: "30m",
            unixTime: 1703797200,
            updatedAt: "2023-12-28T21:29:40.282Z",
            value: 40059728.016008,
          },
          {
            _id: "zeta_total_volume24h_undefined_30m_1703799000",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T21:30:45.297Z",
            humanTime: "2023-12-28T21:30:00.000Z",
            source: "zeta",
            type: "30m",
            unixTime: 1703799000,
            updatedAt: "2023-12-28T21:59:13.819Z",
            value: 39157991.111127,
          },
          {
            _id: "zeta_total_volume24h_undefined_30m_1703800800",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T22:00:08.662Z",
            humanTime: "2023-12-28T22:00:00.000Z",
            source: "zeta",
            type: "30m",
            unixTime: 1703800800,
            updatedAt: "2023-12-28T22:29:49.859Z",
            value: 39018284.347155,
          },
          {
            _id: "zeta_total_volume24h_undefined_30m_1703802600",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T22:30:54.216Z",
            humanTime: "2023-12-28T22:30:00.000Z",
            source: "zeta",
            type: "30m",
            unixTime: 1703802600,
            updatedAt: "2023-12-28T22:59:10.498Z",
            value: 39183006.174384,
          },
          {
            _id: "zeta_total_volume24h_undefined_30m_1703804400",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T23:00:13.968Z",
            humanTime: "2023-12-28T23:00:00.000Z",
            source: "zeta",
            type: "30m",
            unixTime: 1703804400,
            updatedAt: "2023-12-28T23:29:02.188Z",
            value: 39514920.901526,
          },
          {
            _id: "zeta_total_volume24h_undefined_30m_1703806200",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T23:30:09.837Z",
            humanTime: "2023-12-28T23:30:00.000Z",
            source: "zeta",
            type: "30m",
            unixTime: 1703806200,
            updatedAt: "2023-12-28T23:59:32.657Z",
            value: 38956781.206883,
          },
          {
            _id: "zeta_total_volume24h_undefined_30m_1703808000",
            chartName: "total_volume24h",
            createdAt: "2023-12-29T00:00:09.965Z",
            humanTime: "2023-12-29T00:00:00.000Z",
            source: "zeta",
            type: "30m",
            unixTime: 1703808000,
            updatedAt: "2023-12-29T00:28:55.706Z",
            value: 38512979.251939,
          },
          {
            _id: "zeta_total_volume24h_undefined_30m_1703809800",
            chartName: "total_volume24h",
            createdAt: "2023-12-29T00:30:01.767Z",
            humanTime: "2023-12-29T00:30:00.000Z",
            source: "zeta",
            type: "30m",
            unixTime: 1703809800,
            updatedAt: "2023-12-29T00:59:18.830Z",
            value: 37794555.508178,
          },
          {
            _id: "zeta_total_volume24h_undefined_30m_1703811600",
            chartName: "total_volume24h",
            createdAt: "2023-12-29T01:00:07.012Z",
            humanTime: "2023-12-29T01:00:00.000Z",
            source: "zeta",
            type: "30m",
            unixTime: 1703811600,
            updatedAt: "2023-12-29T01:29:38.234Z",
            value: 36994930.782582,
          },
          {
            _id: "zeta_total_volume24h_undefined_30m_1703813400",
            chartName: "total_volume24h",
            createdAt: "2023-12-29T01:30:43.846Z",
            humanTime: "2023-12-29T01:30:00.000Z",
            source: "zeta",
            type: "30m",
            unixTime: 1703813400,
            updatedAt: "2023-12-29T01:59:07.493Z",
            value: 36895101.238801,
          },
          {
            _id: "zeta_total_volume24h_undefined_30m_1703815200",
            chartName: "total_volume24h",
            createdAt: "2023-12-29T02:00:08.177Z",
            humanTime: "2023-12-29T02:00:00.000Z",
            source: "zeta",
            type: "30m",
            unixTime: 1703815200,
            updatedAt: "2023-12-29T02:29:48.794Z",
            value: 37490148.710036,
          },
          {
            _id: "zeta_total_volume24h_undefined_30m_1703817000",
            chartName: "total_volume24h",
            createdAt: "2023-12-29T02:30:53.346Z",
            humanTime: "2023-12-29T02:30:00.000Z",
            source: "zeta",
            type: "30m",
            unixTime: 1703817000,
            updatedAt: "2023-12-29T02:59:31.203Z",
            value: 38173439.551456,
          },
          {
            _id: "zeta_total_volume24h_undefined_30m_1703818800",
            chartName: "total_volume24h",
            createdAt: "2023-12-29T03:00:07.193Z",
            humanTime: "2023-12-29T03:00:00.000Z",
            source: "zeta",
            type: "30m",
            unixTime: 1703818800,
            updatedAt: "2023-12-29T03:18:47.359Z",
            value: 38120152.876604,
          },
        ],
        dooar: [
          {
            _id: "dooar_total_volume24h_Dooar9JkhdZ7J3LHN3A7YCuoGRUggXhQaG4kijfLGU2j_30m_1703736000",
            address: "Dooar9JkhdZ7J3LHN3A7YCuoGRUggXhQaG4kijfLGU2j",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T04:06:27.556Z",
            humanTime: "2023-12-28T04:00:00.000Z",
            source: "dooar",
            symbol: "GMT",
            type: "30m",
            unixTime: 1703736000,
            updatedAt: "2023-12-28T04:06:27.556Z",
            value: 281979151.35692686,
          },
          {
            _id: "dooar_total_volume24h_Dooar9JkhdZ7J3LHN3A7YCuoGRUggXhQaG4kijfLGU2j_30m_1703743200",
            address: "Dooar9JkhdZ7J3LHN3A7YCuoGRUggXhQaG4kijfLGU2j",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T06:24:39.817Z",
            humanTime: "2023-12-28T06:00:00.000Z",
            source: "dooar",
            symbol: "GMT",
            type: "30m",
            unixTime: 1703743200,
            updatedAt: "2023-12-28T06:24:39.817Z",
            value: 278156837.50599915,
          },
          {
            _id: "dooar_total_volume24h_Dooar9JkhdZ7J3LHN3A7YCuoGRUggXhQaG4kijfLGU2j_30m_1703752200",
            address: "Dooar9JkhdZ7J3LHN3A7YCuoGRUggXhQaG4kijfLGU2j",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T08:37:42.404Z",
            humanTime: "2023-12-28T08:30:00.000Z",
            source: "dooar",
            symbol: "GMT",
            type: "30m",
            unixTime: 1703752200,
            updatedAt: "2023-12-28T08:37:42.404Z",
            value: 222578215.73345315,
          },
          {
            _id: "dooar_total_volume24h_Dooar9JkhdZ7J3LHN3A7YCuoGRUggXhQaG4kijfLGU2j_30m_1703759400",
            address: "Dooar9JkhdZ7J3LHN3A7YCuoGRUggXhQaG4kijfLGU2j",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T10:55:02.377Z",
            humanTime: "2023-12-28T10:30:00.000Z",
            source: "dooar",
            symbol: "GMT",
            type: "30m",
            unixTime: 1703759400,
            updatedAt: "2023-12-28T10:55:02.377Z",
            value: 217384567.65422168,
          },
          {
            _id: "dooar_total_volume24h_Dooar9JkhdZ7J3LHN3A7YCuoGRUggXhQaG4kijfLGU2j_30m_1703768400",
            address: "Dooar9JkhdZ7J3LHN3A7YCuoGRUggXhQaG4kijfLGU2j",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T13:08:49.902Z",
            humanTime: "2023-12-28T13:00:00.000Z",
            source: "dooar",
            symbol: "GMT",
            type: "30m",
            unixTime: 1703768400,
            updatedAt: "2023-12-28T13:08:49.902Z",
            value: 224039994.17591387,
          },
          {
            _id: "dooar_total_volume24h_Dooar9JkhdZ7J3LHN3A7YCuoGRUggXhQaG4kijfLGU2j_30m_1703775600",
            address: "Dooar9JkhdZ7J3LHN3A7YCuoGRUggXhQaG4kijfLGU2j",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T15:24:16.967Z",
            humanTime: "2023-12-28T15:00:00.000Z",
            source: "dooar",
            symbol: "GMT",
            type: "30m",
            unixTime: 1703775600,
            updatedAt: "2023-12-28T15:24:16.967Z",
            value: 218278960.4908159,
          },
          {
            _id: "dooar_total_volume24h_Dooar9JkhdZ7J3LHN3A7YCuoGRUggXhQaG4kijfLGU2j_30m_1703784600",
            address: "Dooar9JkhdZ7J3LHN3A7YCuoGRUggXhQaG4kijfLGU2j",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T17:43:42.821Z",
            humanTime: "2023-12-28T17:30:00.000Z",
            source: "dooar",
            symbol: "GMT",
            type: "30m",
            unixTime: 1703784600,
            updatedAt: "2023-12-28T17:43:42.821Z",
            value: 217050440.4903644,
          },
          {
            _id: "dooar_total_volume24h_Dooar9JkhdZ7J3LHN3A7YCuoGRUggXhQaG4kijfLGU2j_30m_1703791800",
            address: "Dooar9JkhdZ7J3LHN3A7YCuoGRUggXhQaG4kijfLGU2j",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T19:58:03.315Z",
            humanTime: "2023-12-28T19:30:00.000Z",
            source: "dooar",
            symbol: "GMT",
            type: "30m",
            unixTime: 1703791800,
            updatedAt: "2023-12-28T19:58:03.315Z",
            value: 222637599.1460946,
          },
          {
            _id: "dooar_total_volume24h_Dooar9JkhdZ7J3LHN3A7YCuoGRUggXhQaG4kijfLGU2j_30m_1703800800",
            address: "Dooar9JkhdZ7J3LHN3A7YCuoGRUggXhQaG4kijfLGU2j",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T22:15:44.769Z",
            humanTime: "2023-12-28T22:00:00.000Z",
            source: "dooar",
            symbol: "GMT",
            type: "30m",
            unixTime: 1703800800,
            updatedAt: "2023-12-28T22:15:44.769Z",
            value: 70643368.22667767,
          },
          {
            _id: "dooar_total_volume24h_Dooar9JkhdZ7J3LHN3A7YCuoGRUggXhQaG4kijfLGU2j_30m_1703809800",
            address: "Dooar9JkhdZ7J3LHN3A7YCuoGRUggXhQaG4kijfLGU2j",
            chartName: "total_volume24h",
            createdAt: "2023-12-29T00:35:01.479Z",
            humanTime: "2023-12-29T00:30:00.000Z",
            source: "dooar",
            symbol: "GMT",
            type: "30m",
            unixTime: 1703809800,
            updatedAt: "2023-12-29T00:35:01.479Z",
            value: 69487586.88971962,
          },
          {
            _id: "dooar_total_volume24h_Dooar9JkhdZ7J3LHN3A7YCuoGRUggXhQaG4kijfLGU2j_30m_1703817000",
            address: "Dooar9JkhdZ7J3LHN3A7YCuoGRUggXhQaG4kijfLGU2j",
            chartName: "total_volume24h",
            createdAt: "2023-12-29T02:49:51.661Z",
            humanTime: "2023-12-29T02:30:00.000Z",
            source: "dooar",
            symbol: "GMT",
            type: "30m",
            unixTime: 1703817000,
            updatedAt: "2023-12-29T02:49:51.661Z",
            value: 68832736.37424561,
          },
        ],
        lifinity: [
          {
            _id: "lifinity_total_volume24h_EewxydAPCCVuNEyrVN68PuSYdQ7wKn27V9Gjeoi8dy3S_30m_1703736000",
            address: "EewxydAPCCVuNEyrVN68PuSYdQ7wKn27V9Gjeoi8dy3S",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T04:06:37.587Z",
            humanTime: "2023-12-28T04:00:00.000Z",
            source: "lifinity",
            symbol: "LFNTY",
            type: "30m",
            unixTime: 1703736000,
            updatedAt: "2023-12-28T04:06:37.587Z",
            value: 212600368.3194041,
          },
          {
            _id: "lifinity_total_volume24h_EewxydAPCCVuNEyrVN68PuSYdQ7wKn27V9Gjeoi8dy3S_30m_1703743200",
            address: "EewxydAPCCVuNEyrVN68PuSYdQ7wKn27V9Gjeoi8dy3S",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T06:24:55.812Z",
            humanTime: "2023-12-28T06:00:00.000Z",
            source: "lifinity",
            symbol: "LFNTY",
            type: "30m",
            unixTime: 1703743200,
            updatedAt: "2023-12-28T06:24:55.812Z",
            value: 208581613.18793434,
          },
          {
            _id: "lifinity_total_volume24h_EewxydAPCCVuNEyrVN68PuSYdQ7wKn27V9Gjeoi8dy3S_30m_1703752200",
            address: "EewxydAPCCVuNEyrVN68PuSYdQ7wKn27V9Gjeoi8dy3S",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T08:37:51.921Z",
            humanTime: "2023-12-28T08:30:00.000Z",
            source: "lifinity",
            symbol: "LFNTY",
            type: "30m",
            unixTime: 1703752200,
            updatedAt: "2023-12-28T08:37:51.921Z",
            value: 216711722.41892862,
          },
          {
            _id: "lifinity_total_volume24h_EewxydAPCCVuNEyrVN68PuSYdQ7wKn27V9Gjeoi8dy3S_30m_1703759400",
            address: "EewxydAPCCVuNEyrVN68PuSYdQ7wKn27V9Gjeoi8dy3S",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T10:55:13.204Z",
            humanTime: "2023-12-28T10:30:00.000Z",
            source: "lifinity",
            symbol: "LFNTY",
            type: "30m",
            unixTime: 1703759400,
            updatedAt: "2023-12-28T10:55:13.204Z",
            value: 225849667.16168866,
          },
          {
            _id: "lifinity_total_volume24h_EewxydAPCCVuNEyrVN68PuSYdQ7wKn27V9Gjeoi8dy3S_30m_1703768400",
            address: "EewxydAPCCVuNEyrVN68PuSYdQ7wKn27V9Gjeoi8dy3S",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T13:09:03.312Z",
            humanTime: "2023-12-28T13:00:00.000Z",
            source: "lifinity",
            symbol: "LFNTY",
            type: "30m",
            unixTime: 1703768400,
            updatedAt: "2023-12-28T13:09:03.312Z",
            value: 230302475.9374252,
          },
          {
            _id: "lifinity_total_volume24h_EewxydAPCCVuNEyrVN68PuSYdQ7wKn27V9Gjeoi8dy3S_30m_1703775600",
            address: "EewxydAPCCVuNEyrVN68PuSYdQ7wKn27V9Gjeoi8dy3S",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T15:24:30.930Z",
            humanTime: "2023-12-28T15:00:00.000Z",
            source: "lifinity",
            symbol: "LFNTY",
            type: "30m",
            unixTime: 1703775600,
            updatedAt: "2023-12-28T15:24:30.930Z",
            value: 212802699.55948544,
          },
          {
            _id: "lifinity_total_volume24h_EewxydAPCCVuNEyrVN68PuSYdQ7wKn27V9Gjeoi8dy3S_30m_1703784600",
            address: "EewxydAPCCVuNEyrVN68PuSYdQ7wKn27V9Gjeoi8dy3S",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T17:43:53.104Z",
            humanTime: "2023-12-28T17:30:00.000Z",
            source: "lifinity",
            symbol: "LFNTY",
            type: "30m",
            unixTime: 1703784600,
            updatedAt: "2023-12-28T17:43:53.104Z",
            value: 217750356.31499517,
          },
          {
            _id: "lifinity_total_volume24h_EewxydAPCCVuNEyrVN68PuSYdQ7wKn27V9Gjeoi8dy3S_30m_1703791800",
            address: "EewxydAPCCVuNEyrVN68PuSYdQ7wKn27V9Gjeoi8dy3S",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T19:58:14.059Z",
            humanTime: "2023-12-28T19:30:00.000Z",
            source: "lifinity",
            symbol: "LFNTY",
            type: "30m",
            unixTime: 1703791800,
            updatedAt: "2023-12-28T19:58:14.059Z",
            value: 215688784.922711,
          },
          {
            _id: "lifinity_total_volume24h_EewxydAPCCVuNEyrVN68PuSYdQ7wKn27V9Gjeoi8dy3S_30m_1703800800",
            address: "EewxydAPCCVuNEyrVN68PuSYdQ7wKn27V9Gjeoi8dy3S",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T22:16:03.265Z",
            humanTime: "2023-12-28T22:00:00.000Z",
            source: "lifinity",
            symbol: "LFNTY",
            type: "30m",
            unixTime: 1703800800,
            updatedAt: "2023-12-28T22:16:03.265Z",
            value: 214894303.29948655,
          },
          {
            _id: "lifinity_total_volume24h_EewxydAPCCVuNEyrVN68PuSYdQ7wKn27V9Gjeoi8dy3S_30m_1703809800",
            address: "EewxydAPCCVuNEyrVN68PuSYdQ7wKn27V9Gjeoi8dy3S",
            chartName: "total_volume24h",
            createdAt: "2023-12-29T00:35:09.170Z",
            humanTime: "2023-12-29T00:30:00.000Z",
            source: "lifinity",
            symbol: "LFNTY",
            type: "30m",
            unixTime: 1703809800,
            updatedAt: "2023-12-29T00:35:09.170Z",
            value: 208947615.51391414,
          },
          {
            _id: "lifinity_total_volume24h_EewxydAPCCVuNEyrVN68PuSYdQ7wKn27V9Gjeoi8dy3S_30m_1703817000",
            address: "EewxydAPCCVuNEyrVN68PuSYdQ7wKn27V9Gjeoi8dy3S",
            chartName: "total_volume24h",
            createdAt: "2023-12-29T02:50:00.718Z",
            humanTime: "2023-12-29T02:30:00.000Z",
            source: "lifinity",
            symbol: "LFNTY",
            type: "30m",
            unixTime: 1703817000,
            updatedAt: "2023-12-29T02:50:00.718Z",
            value: 204759673.0372777,
          },
        ],
        orca: [
          {
            _id: "orca_total_volume24h_orcaEKTdK7LKz57vaAYr9QeNsVEPfiu6QeMU1kektZE_30m_1703736000",
            address: "orcaEKTdK7LKz57vaAYr9QeNsVEPfiu6QeMU1kektZE",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T04:09:34.328Z",
            humanTime: "2023-12-28T04:00:00.000Z",
            source: "orca",
            symbol: "ORCA",
            type: "30m",
            unixTime: 1703736000,
            updatedAt: "2023-12-28T04:09:34.328Z",
            value: 263809.40978708596,
          },
          {
            _id: "orca_total_volume24h_orcaEKTdK7LKz57vaAYr9QeNsVEPfiu6QeMU1kektZE_30m_1703743200",
            address: "orcaEKTdK7LKz57vaAYr9QeNsVEPfiu6QeMU1kektZE",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T06:26:49.787Z",
            humanTime: "2023-12-28T06:00:00.000Z",
            source: "orca",
            symbol: "ORCA",
            type: "30m",
            unixTime: 1703743200,
            updatedAt: "2023-12-28T06:26:49.787Z",
            value: 263809.40978708596,
          },
          {
            _id: "orca_total_volume24h_orcaEKTdK7LKz57vaAYr9QeNsVEPfiu6QeMU1kektZE_30m_1703752200",
            address: "orcaEKTdK7LKz57vaAYr9QeNsVEPfiu6QeMU1kektZE",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T08:39:41.481Z",
            humanTime: "2023-12-28T08:30:00.000Z",
            source: "orca",
            symbol: "ORCA",
            type: "30m",
            unixTime: 1703752200,
            updatedAt: "2023-12-28T08:39:41.481Z",
            value: 263809.40978708596,
          },
          {
            _id: "orca_total_volume24h_orcaEKTdK7LKz57vaAYr9QeNsVEPfiu6QeMU1kektZE_30m_1703759400",
            address: "orcaEKTdK7LKz57vaAYr9QeNsVEPfiu6QeMU1kektZE",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T10:57:02.259Z",
            humanTime: "2023-12-28T10:30:00.000Z",
            source: "orca",
            symbol: "ORCA",
            type: "30m",
            unixTime: 1703759400,
            updatedAt: "2023-12-28T10:57:02.259Z",
            value: 263809.40978708596,
          },
          {
            _id: "orca_total_volume24h_orcaEKTdK7LKz57vaAYr9QeNsVEPfiu6QeMU1kektZE_30m_1703768400",
            address: "orcaEKTdK7LKz57vaAYr9QeNsVEPfiu6QeMU1kektZE",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T13:10:49.710Z",
            humanTime: "2023-12-28T13:00:00.000Z",
            source: "orca",
            symbol: "ORCA",
            type: "30m",
            unixTime: 1703768400,
            updatedAt: "2023-12-28T13:10:49.710Z",
            value: 263809.40978708596,
          },
          {
            _id: "orca_total_volume24h_orcaEKTdK7LKz57vaAYr9QeNsVEPfiu6QeMU1kektZE_30m_1703775600",
            address: "orcaEKTdK7LKz57vaAYr9QeNsVEPfiu6QeMU1kektZE",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T15:26:28.440Z",
            humanTime: "2023-12-28T15:00:00.000Z",
            source: "orca",
            symbol: "ORCA",
            type: "30m",
            unixTime: 1703775600,
            updatedAt: "2023-12-28T15:26:28.440Z",
            value: 263809.40978708596,
          },
          {
            _id: "orca_total_volume24h_orcaEKTdK7LKz57vaAYr9QeNsVEPfiu6QeMU1kektZE_30m_1703784600",
            address: "orcaEKTdK7LKz57vaAYr9QeNsVEPfiu6QeMU1kektZE",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T17:45:48.975Z",
            humanTime: "2023-12-28T17:30:00.000Z",
            source: "orca",
            symbol: "ORCA",
            type: "30m",
            unixTime: 1703784600,
            updatedAt: "2023-12-28T17:45:48.975Z",
            value: 263809.40978708596,
          },
          {
            _id: "orca_total_volume24h_orcaEKTdK7LKz57vaAYr9QeNsVEPfiu6QeMU1kektZE_30m_1703793600",
            address: "orcaEKTdK7LKz57vaAYr9QeNsVEPfiu6QeMU1kektZE",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T20:00:10.278Z",
            humanTime: "2023-12-28T20:00:00.000Z",
            source: "orca",
            symbol: "ORCA",
            type: "30m",
            unixTime: 1703793600,
            updatedAt: "2023-12-28T20:00:10.278Z",
            value: 263809.40978708596,
          },
          {
            _id: "orca_total_volume24h_orcaEKTdK7LKz57vaAYr9QeNsVEPfiu6QeMU1kektZE_30m_1703800800",
            address: "orcaEKTdK7LKz57vaAYr9QeNsVEPfiu6QeMU1kektZE",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T22:17:58.995Z",
            humanTime: "2023-12-28T22:00:00.000Z",
            source: "orca",
            symbol: "ORCA",
            type: "30m",
            unixTime: 1703800800,
            updatedAt: "2023-12-28T22:17:58.995Z",
            value: 263809.40978708596,
          },
          {
            _id: "orca_total_volume24h_orcaEKTdK7LKz57vaAYr9QeNsVEPfiu6QeMU1kektZE_30m_1703809800",
            address: "orcaEKTdK7LKz57vaAYr9QeNsVEPfiu6QeMU1kektZE",
            chartName: "total_volume24h",
            createdAt: "2023-12-29T00:37:04.833Z",
            humanTime: "2023-12-29T00:30:00.000Z",
            source: "orca",
            symbol: "ORCA",
            type: "30m",
            unixTime: 1703809800,
            updatedAt: "2023-12-29T00:37:04.833Z",
            value: 263809.40978708596,
          },
          {
            _id: "orca_total_volume24h_orcaEKTdK7LKz57vaAYr9QeNsVEPfiu6QeMU1kektZE_30m_1703817000",
            address: "orcaEKTdK7LKz57vaAYr9QeNsVEPfiu6QeMU1kektZE",
            chartName: "total_volume24h",
            createdAt: "2023-12-29T02:52:07.617Z",
            humanTime: "2023-12-29T02:30:00.000Z",
            source: "orca",
            symbol: "ORCA",
            type: "30m",
            unixTime: 1703817000,
            updatedAt: "2023-12-29T02:52:07.617Z",
            value: 263809.40978708596,
          },
        ],
        raydium: [
          {
            _id: "raydium_total_volume24h_4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R_30m_1703736000",
            address: "4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T04:09:05.442Z",
            humanTime: "2023-12-28T04:00:00.000Z",
            source: "raydium",
            symbol: "RAY",
            type: "30m",
            unixTime: 1703736000,
            updatedAt: "2023-12-28T04:09:05.442Z",
            value: 407182865.2135469,
          },
          {
            _id: "raydium_total_volume24h_4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R_30m_1703743200",
            address: "4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T06:26:33.661Z",
            humanTime: "2023-12-28T06:00:00.000Z",
            source: "raydium",
            symbol: "RAY",
            type: "30m",
            unixTime: 1703743200,
            updatedAt: "2023-12-28T06:26:33.661Z",
            value: 393167432.61709756,
          },
          {
            _id: "raydium_total_volume24h_4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R_30m_1703752200",
            address: "4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T08:39:25.370Z",
            humanTime: "2023-12-28T08:30:00.000Z",
            source: "raydium",
            symbol: "RAY",
            type: "30m",
            unixTime: 1703752200,
            updatedAt: "2023-12-28T08:39:25.370Z",
            value: 390135083.73626035,
          },
          {
            _id: "raydium_total_volume24h_4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R_30m_1703759400",
            address: "4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T10:56:44.895Z",
            humanTime: "2023-12-28T10:30:00.000Z",
            source: "raydium",
            symbol: "RAY",
            type: "30m",
            unixTime: 1703759400,
            updatedAt: "2023-12-28T10:56:44.895Z",
            value: 380651219.8282686,
          },
          {
            _id: "raydium_total_volume24h_4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R_30m_1703768400",
            address: "4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T13:10:31.340Z",
            humanTime: "2023-12-28T13:00:00.000Z",
            source: "raydium",
            symbol: "RAY",
            type: "30m",
            unixTime: 1703768400,
            updatedAt: "2023-12-28T13:10:31.340Z",
            value: 378706249.2201009,
          },
          {
            _id: "raydium_total_volume24h_4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R_30m_1703775600",
            address: "4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T15:26:08.437Z",
            humanTime: "2023-12-28T15:00:00.000Z",
            source: "raydium",
            symbol: "RAY",
            type: "30m",
            unixTime: 1703775600,
            updatedAt: "2023-12-28T15:26:08.437Z",
            value: 365081301.4520358,
          },
          {
            _id: "raydium_total_volume24h_4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R_30m_1703784600",
            address: "4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T17:45:32.261Z",
            humanTime: "2023-12-28T17:30:00.000Z",
            source: "raydium",
            symbol: "RAY",
            type: "30m",
            unixTime: 1703784600,
            updatedAt: "2023-12-28T17:45:32.261Z",
            value: 343429170.9742884,
          },
          {
            _id: "raydium_total_volume24h_4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R_30m_1703791800",
            address: "4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T19:59:50.132Z",
            humanTime: "2023-12-28T19:30:00.000Z",
            source: "raydium",
            symbol: "RAY",
            type: "30m",
            unixTime: 1703791800,
            updatedAt: "2023-12-28T19:59:50.132Z",
            value: 342882125.4967783,
          },
          {
            _id: "raydium_total_volume24h_4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R_30m_1703800800",
            address: "4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T22:17:44.337Z",
            humanTime: "2023-12-28T22:00:00.000Z",
            source: "raydium",
            symbol: "RAY",
            type: "30m",
            unixTime: 1703800800,
            updatedAt: "2023-12-28T22:17:44.337Z",
            value: 336769880.35534686,
          },
          {
            _id: "raydium_total_volume24h_4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R_30m_1703809800",
            address: "4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R",
            chartName: "total_volume24h",
            createdAt: "2023-12-29T00:36:44.607Z",
            humanTime: "2023-12-29T00:30:00.000Z",
            source: "raydium",
            symbol: "RAY",
            type: "30m",
            unixTime: 1703809800,
            updatedAt: "2023-12-29T00:36:44.607Z",
            value: 333924903.47087735,
          },
          {
            _id: "raydium_total_volume24h_4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R_30m_1703817000",
            address: "4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R",
            chartName: "total_volume24h",
            createdAt: "2023-12-29T02:51:50.659Z",
            humanTime: "2023-12-29T02:30:00.000Z",
            source: "raydium",
            symbol: "RAY",
            type: "30m",
            unixTime: 1703817000,
            updatedAt: "2023-12-29T02:51:50.659Z",
            value: 303183921.1172698,
          },
        ],
        whirlpool: [
          {
            _id: "whirlpool_total_volume24h_whirLbMiicVdio4qvUfM5KAg6Ct8VwpYzGff3uctyCc_30m_1703743200",
            address: "whirLbMiicVdio4qvUfM5KAg6Ct8VwpYzGff3uctyCc",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T06:25:12.970Z",
            humanTime: "2023-12-28T06:00:00.000Z",
            source: "whirlpool",
            symbol: "ORCA",
            type: "30m",
            unixTime: 1703743200,
            updatedAt: "2023-12-28T06:25:12.970Z",
            value: 392514186.8797251,
          },
          {
            _id: "whirlpool_total_volume24h_whirLbMiicVdio4qvUfM5KAg6Ct8VwpYzGff3uctyCc_30m_1703752200",
            address: "whirLbMiicVdio4qvUfM5KAg6Ct8VwpYzGff3uctyCc",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T08:38:06.236Z",
            humanTime: "2023-12-28T08:30:00.000Z",
            source: "whirlpool",
            symbol: "ORCA",
            type: "30m",
            unixTime: 1703752200,
            updatedAt: "2023-12-28T08:38:06.236Z",
            value: 401262455.85981935,
          },
          {
            _id: "whirlpool_total_volume24h_whirLbMiicVdio4qvUfM5KAg6Ct8VwpYzGff3uctyCc_30m_1703759400",
            address: "whirLbMiicVdio4qvUfM5KAg6Ct8VwpYzGff3uctyCc",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T10:55:30.980Z",
            humanTime: "2023-12-28T10:30:00.000Z",
            source: "whirlpool",
            symbol: "ORCA",
            type: "30m",
            unixTime: 1703759400,
            updatedAt: "2023-12-28T10:55:30.980Z",
            value: 401013917.8207369,
          },
          {
            _id: "whirlpool_total_volume24h_whirLbMiicVdio4qvUfM5KAg6Ct8VwpYzGff3uctyCc_30m_1703768400",
            address: "whirLbMiicVdio4qvUfM5KAg6Ct8VwpYzGff3uctyCc",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T13:09:16.027Z",
            humanTime: "2023-12-28T13:00:00.000Z",
            source: "whirlpool",
            symbol: "ORCA",
            type: "30m",
            unixTime: 1703768400,
            updatedAt: "2023-12-28T13:09:16.027Z",
            value: 401013917.8207369,
          },
          {
            _id: "whirlpool_total_volume24h_whirLbMiicVdio4qvUfM5KAg6Ct8VwpYzGff3uctyCc_30m_1703775600",
            address: "whirLbMiicVdio4qvUfM5KAg6Ct8VwpYzGff3uctyCc",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T15:24:48.000Z",
            humanTime: "2023-12-28T15:00:00.000Z",
            source: "whirlpool",
            symbol: "ORCA",
            type: "30m",
            unixTime: 1703775600,
            updatedAt: "2023-12-28T15:24:48.000Z",
            value: 446399796.49372685,
          },
          {
            _id: "whirlpool_total_volume24h_whirLbMiicVdio4qvUfM5KAg6Ct8VwpYzGff3uctyCc_30m_1703784600",
            address: "whirLbMiicVdio4qvUfM5KAg6Ct8VwpYzGff3uctyCc",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T17:44:08.782Z",
            humanTime: "2023-12-28T17:30:00.000Z",
            source: "whirlpool",
            symbol: "ORCA",
            type: "30m",
            unixTime: 1703784600,
            updatedAt: "2023-12-28T17:44:08.782Z",
            value: 458732010.0926784,
          },
          {
            _id: "whirlpool_total_volume24h_whirLbMiicVdio4qvUfM5KAg6Ct8VwpYzGff3uctyCc_30m_1703791800",
            address: "whirLbMiicVdio4qvUfM5KAg6Ct8VwpYzGff3uctyCc",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T19:58:26.459Z",
            humanTime: "2023-12-28T19:30:00.000Z",
            source: "whirlpool",
            symbol: "ORCA",
            type: "30m",
            unixTime: 1703791800,
            updatedAt: "2023-12-28T19:58:26.459Z",
            value: 458998017.1539037,
          },
          {
            _id: "whirlpool_total_volume24h_whirLbMiicVdio4qvUfM5KAg6Ct8VwpYzGff3uctyCc_30m_1703800800",
            address: "whirLbMiicVdio4qvUfM5KAg6Ct8VwpYzGff3uctyCc",
            chartName: "total_volume24h",
            createdAt: "2023-12-28T22:16:20.169Z",
            humanTime: "2023-12-28T22:00:00.000Z",
            source: "whirlpool",
            symbol: "ORCA",
            type: "30m",
            unixTime: 1703800800,
            updatedAt: "2023-12-28T22:16:20.169Z",
            value: 467172344.2031466,
          },
          {
            _id: "whirlpool_total_volume24h_whirLbMiicVdio4qvUfM5KAg6Ct8VwpYzGff3uctyCc_30m_1703809800",
            address: "whirLbMiicVdio4qvUfM5KAg6Ct8VwpYzGff3uctyCc",
            chartName: "total_volume24h",
            createdAt: "2023-12-29T00:35:30.170Z",
            humanTime: "2023-12-29T00:30:00.000Z",
            source: "whirlpool",
            symbol: "ORCA",
            type: "30m",
            unixTime: 1703809800,
            updatedAt: "2023-12-29T00:35:30.170Z",
            value: 468984111.448975,
          },
          {
            _id: "whirlpool_total_volume24h_whirLbMiicVdio4qvUfM5KAg6Ct8VwpYzGff3uctyCc_30m_1703817000",
            address: "whirLbMiicVdio4qvUfM5KAg6Ct8VwpYzGff3uctyCc",
            chartName: "total_volume24h",
            createdAt: "2023-12-29T02:50:23.760Z",
            humanTime: "2023-12-29T02:30:00.000Z",
            source: "whirlpool",
            symbol: "ORCA",
            type: "30m",
            unixTime: 1703817000,
            updatedAt: "2023-12-29T02:50:23.760Z",
            value: 489391904.53919834,
          },
        ],
      };
      let itemsKeys = Object.keys(items);
      let itemsValues = Object.values(items);
      this.line_personnel.legend.data = itemsKeys;
      itemsValues.forEach((item, index) => {
        let time = "";
        let obj = {
          name: "",
          type: "line",
          areaStyle: {},
          emphasis: {
            focus: "series",
          },
          data: [],
        };
        obj.name = itemsKeys[index];
        item.forEach((items) => {
          if (index == 0) {
            time = moment.utc(items.humanTime).local().format("M-DD hh:mm");
            this.line_personnel.xAxis[0].data.push(time);
          }
          obj.data.push(items.value);
        });
        this.line_personnel.series.push(obj);
      });
      this.$forceUpdate();
    },
    async getLinkStatusInfo() {
      const { blockHeight, absoluteSlot, transactionCount, currentEpoch } =
        await getLinkStatusInfoApi({
          token: this.$solscanKey,
        });
      this.statusCardList[0].data = transactionCount;
      this.statusCardList[1].data = absoluteSlot;
      this.statusCardList[2].data = blockHeight;
      this.statusCardList[3].data = currentEpoch;
    },
    // 获取批次列表
    async getBatchListApi() {
      const { code, results } = await getBatchListApi({
        manor_id: this.farmID,
        pn: this.current_page,
        title: this.linkName,
      });
      if (code != 0) return;
      this.total = results.count;
      this.current_page = results.pn;
      this.tableData = results.data;
    },

    async getUplinkAppend(data) {
      const { code, results } = await postTranceChainApi({
        secret_key: this.secret_key,
        data,
        kind: 3,
      });
      if (code != 0) return;
      if (!results) {
        this.$notify.error({
          title: "错误",
          message: "请勿重复上链",
        });
        return;
      }
      this.linkName = undefined;
      this.getBatchListApi();
      this.$notify({
        title: "提示",
        message: "上链成功！",
        type: "success",
      });
    },
    async getUplinkInitialize() {
      const { code } = await postTranceChainApi({
        secret_key: this.secret_key,
        data: [],
        kind: 0,
      });
      if (code != 0) return;
      localStorage.setItem("initialize", "1");
    },
    async getUplinkAll() {
      await postTranceChainApi({
        secret_key: this.secret_key,
        kind: 1,
      });
    },
    handleCurrentChange(val) {
      this.current_page = val;
      this.getBatchListApi();
    },
    getLinkSearch() {
      if (!this.linkName) {
        this.$notify.error({
          title: "错误",
          message: "请输入批次号",
        });
        return;
      }
      let isLength = this.tableData.find((item) => {
        return item.SN == this.linkName;
      });
      if (!isLength || !isLength.tx) {
        this.$confirm("没有相关信息，是否上链?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.getUplinkAppend(this.linkName);
          })
          .catch(() => {});
      } else {
        this.tableData = [isLength];
      }
    },
  },
};
</script>

<style lang="less" scoped>
.outDiv {
  .up-link {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background: #071a2c;
    padding: 29px 48px;

    .link-info,
    .link-status {
      width: 1170px;
      height: 875px;
      background: #0e2138;
      padding: 24px 36px;
      .screen-title {
        display: flex;
        margin-bottom: 25px;
        padding-right: 30px;
        align-items: flex-start;
        .left-line {
          background-color: #3e90e5;
          width: 4px;
          height: 22px;
          border-radius: 2px;
          margin: 0 11px 0 0;
        }
        p {
          color: #fff;
          font-size: 22px;
          font-weight: 400;
        }
      }
    }
    .link-info {
      .result-search {
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        margin-bottom: 32px;

        .name {
          margin-left: 36px;
          display: flex;
          align-items: center;

          span {
            font-size: 14px;
            margin-right: 20px;
          }

          /deep/.el-input {
            width: 200px;
          }

          /deep/.el-input__inner {
            width: 200px;
            background-color: #081c30;
            border: 1px solid #385576;
            color: #57708d;
            font-size: 14px;
          }
        }
        .searchBtn {
          width: 90px;
          height: 40px;
          background: #3e90e5;
          border-radius: 3px;
          font-size: 14px;
          color: #fff;
          margin-left: 20px;
          line-height: 40px;
          text-align: center;
          cursor: pointer;

          &:hover {
            opacity: 0.9;
          }
        }
        .calamity-type-name.name {
          margin-left: 0;

          /deep/.el-input {
            width: 253px;

            .el-input__inner {
              width: 100%;
            }
          }
        }
      }
    }
    .link-status {
      width: 636px;
      .status-card-box {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .card-items {
          width: 266px;
          height: 110px;
          display: flex;
          align-items: center;
          border-radius: 5px;
          margin-bottom: 22px;
          padding: 0 23px;
          .card-imgs {
            width: 48px;
            height: 48px;
            margin-right: 14px;
          }
          .card-infos {
            width: calc(100% - 62px);
            color: #fff;

            p {
              width: 100%;
              font-size: 14px;
              font-weight: 400;
              margin-bottom: 10px;
            }
            .count-up {
              font-size: 22px;
              font-weight: bold;
              margin-bottom: 0;
            }
          }
        }
      }
      .chart-personnel {
        width: 100%;
        height: 60%;

        .v-chart {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
